// GLOBAL STYLES
.loader-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color__: #ffffffe3;
    background-color: white;
    display: block;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's above other elements */
}
.loader {
  text-align: center;
  vertical-align: middle;
  position: fixed;
  display: flex;
  padding: 150px;
  top: 25%;
    left: 37%;
    span {
      display: block;
      width: 20px;
      height: 20px;
      background: #eee;
      border-radius: 50%;
      margin: 0 5px;
      box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
    }
    span:nth-child(2) {
      background: #f07e6e;
    }
    span:nth-child(3) {
      background: #84cdfa;
    }
    span:nth-child(4) {
      background: #5ad1cd;
    }
    span:not(:last-child) {
      animation: animate 1.5s linear infinite;
    }
     span:last-child {
      animation: jump 1.5s ease-in-out infinite;
    }

}

@keyframes animate {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(30px);
  }
}


@keyframes jump {
  0% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(10px, -10px);
  }
  20% {
    transform: translate(20px, 10px);
  }
  30% {
    transform: translate(30px, -50px);
  }
  70% {
    transform: translate(-150px, -50px);
  }
  80% {
    transform: translate(-140px, 10px);
  }
  90% {
    transform: translate(-130px, -10px);
  }
  100% {
    transform: translate(-120px, 0);
  }
}


.required {
    color: #f44336;
    font-size: 7px;
    position: absolute;
    margin-left: 2px;

    &::before {
        font-family: "Font Awesome 6 Free";
        font-weight: 900;
        content: "\f005";
    }
}

#back-to-top {
    z-index: 100 !important;
}

.modal-body,
.modal-footer {
    background-color: $white;
}

.btn-move {
    cursor: move !important;
}

// Transparent buttons, color on hover
[class*="btn-transparent-"] {
    background: rgb(105 112 122 / 10%) !important;
    &:hover {
        color: $white;
    }
}

.btn-transparent-secondary {
    &:hover {
        background: $secondary !important;
    }
}

.btn-transparent-danger {
    &:hover {
        background: $danger !important;
    }
}

.btn-transparent-gray {
    &:hover {
        background: var(--bs-gray) !important;
    }
}

.custom-radio {
  .custom-control-input:checked {
    ~ .label-true::before,
    ~ .label-false::before {
      background-color: var(--green) !important;
      border-color: var(--green) !important;
    }
    ~ .label-false::before {
      background-color: var(--red) !important;
      border-color: var(--red) !important;
    }
  }
}

// Datatable anchor customization
.custom-datatable-anchor td {
    padding: 0px;

    a {
        padding: 8px;
        display: block;
        color: inherit;
    }
}

// Login/Signup page

.login-page {
    background: var(--primary-bg-color) !important;

    .header-brand-img {
        max-width: 85%;
    }
}


.course_sidebar a{
        float:left;
    }

.correct_answer {
  border: 3px solid #008100;
}

li.list-group-item.correct_answer{
  border-top-width: 3px !important;
}

.disable_text_color {
  color: black !important;
}
.page-header{
    margin: 0.15rem 1rem 0.15rem;
}

:root {
  --primary-bg-color: #348cd4;

  }
  body {
    background-color: #ecf2f5;
}
.list-group-item.active {
    background-color: #348cd4;
    font-weight: bold;

    color: white!important;
//     border-color: #025eff00;
}
@media (min-width: 992px){
.sidebar-mini.sidenav-toggled .app-header.header {
        padding-bottom: 0px;
        padding-top: 0px;
        padding-left: 75px;
    /* max-height: 60px; */
}
.app.sidebar-mini.sidenav-toggled .side-header {
    max-height: 51px;
}
.course_sidebar{
    max-height: 50px;
}
}

.card-header {
    max-height: 47px;
}
.br-right{
    border-right: 1px solid #dbdbdb;
    }

.br-top{
    border-top: 1px solid #dbdbdb;
    }
.br-bottom{
    border-bottom: 1px solid #676767;
    }

.view_training_info .invalid-feedback {
    display: none!important;
}

.trn_more_filter{
    /* float: right; */
    /* max-width: 50%; */
    z-index: 9;
    margin-top: -30px;
    border-bottom: 1px solid #dbdbdb;
    padding-bottom: 8px;
    margin-bottom: 8px;
}
#course_ilt_filter_form .select2-container{
    z-index:10;
}
.any_filter_check{
z-index:11;
}
.select2-dropdown{
    z-index: 18!important;
}



body *::-webkit-scrollbar-thumb, body *:hover::-webkit-scrollbar-thumb  {
    background: rgb(152 152 152);
    border-radius: 8px;
}

body *::-webkit-scrollbar {
    border: 1px solid #d4d4d4;
}

.select2-container--default .select2-results>.select2-results__options{
    overflow-x: hidden;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple{
    background: white;
}

.select2-container--default .select2-selection--single,
.select2-container--default .select2-selection--multiple,
.select2-container--default.select2-container--focus .select2-selection--multiple, input,.form-control{
    border-radius: 0px;
}
.product_create_form label{
    font-weight: bold;
}



@media (max-width:768px)  {
.xxx .sidebar-collapse{
    top: 77px;
    z-index: 3;
    left: 0px;
}
#course-page-header.crs_enrolled_hdr {
    position: relative!important;
    top: auto!important;
    z-index: 2!important;
    margin-left: auto!important;
}
.learner_main_container{
    margin-top: -38px;
}
}

.custom-tab-content .btn i{
  color: var(--primary-bg-color) !important;
}