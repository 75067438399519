.feedback-section {
  $sub-heading-margin-bottom: -3px;
  $sub-heading-margin-top: -16px;
  $heading-float: right !important;
  $heading-margin-top: -16px;
  $m-t-n-sm-margin-top: -18px;
  $m-l-n-xs-margin-left: -5px;
  $form-row-margin-bottom: 10px;

  .sub-heading-para {
    margin-bottom: $sub-heading-margin-bottom;
    margin-top: $sub-heading-margin-top;
  }

  .heading {
    float: $heading-float;
    margin-top: $heading-margin-top;
  }

  .m-t-n-sm {
    margin-top: $m-t-n-sm-margin-top;
  }

  .m-l-n-xs {
    margin-left: $m-l-n-xs-margin-left;
  }

  .form-row {
    margin-bottom: $form-row-margin-bottom;
  }

  .required {
    color: #f44336;
    font-size: 8px;
    position: absolute;
    margin-left: -9px;

    &::before {
      font-family: "Font Awesome 6 Free";
      font-weight: 900;
      content: "\f005";
    }
  }

  $heading-font-size: 22px;

  .overall-heading {
    font-size: $heading-font-size;
    margin-top: 5px;
  }

  .recommend-rating{
    width: 103%;
  }

  .smiley-radio {
    width: 50px;
    margin-bottom: 3rem;
    border: none;
    transition: border .2s ease;
    cursor: pointer;

    &:before {
      font-size: 50px;
    }

    &.color1,
    &.color2,
    &.color3,
    &.color4,
    &.color5,
    &.color6,
    &.color7,
    &.color8,
    &.color9,
    &.color10,
    input.fas {
      color: #cacaca;
    }

    &.color1:checked,
    &:hover.color1 {
      color: #9f0003;
    }

    &.color2 {
      color: #b61113;
      
    }

    &.color2:checked,
    &:hover.color2 {
      color: blue;
    }

    &.color3{
      color: #c42604;
    }

    &.color3:checked,
    &:hover.color3 {
      color: blue;
    }

    &.color4{
      color: #fc7204;
    }

    &.color4:checked,
    &:hover.color4 {
      color: blue;
    }

    &.color5 {
      color: #fca904;
    }

    &.color5:checked,
    &:hover.color5 {
      color: blue;
    }

    &.color6 {
      color: #fcde04;
    }

    &.color6:checked,
    &:hover.color6 {
      color: blue;
    }

    &.color7 {
      color: #dcfe04;
    }

    &.color7:checked,
    &:hover.color7 {
      color: blue;
    }

    &.color8{
      color: #a4d504;
    }

    &.color8:checked,
    &:hover.color8 {
      color: blue;
    }

    &.color9 {
      color: #2cbe04;
    }

    &.color9:checked,
    &:hover.color9 {
      color: blue;
    }

    &.color10 {
      color: #249604;
    }

    &.color10:checked,
    &:hover.color10 {
     color:blue;
    }
  }

  $stars-color: #ccc;
  $stars-font-size: 20px;

  .stars {
    label {
      font-size: $stars-font-size;
      color: $stars-color;
      margin-top: -2rem;

    }
  }

  $star-color: #fd4;

  #star-1:hover~.stars .star-1,
  #star-1:checked~.stars .star-1 {
    color: $star-color;
  }

  @mixin star($i) {
    @for $j from 1 through $i {

      #star-#{$i}:hover~.stars .star-#{$j},
      #star-#{$i}:checked~.stars .star-#{$j} {
        color: $star-color;
        cursor: pointer;
      }
    }
  }

  @include star(2);
  @include star(3);
  @include star(4);
  @include star(5);
  @include star(6);
  @include star(7);
  @include star(8);
  @include star(9);
  @include star(10);
  @include star(11);
  @include star(12);
  @include star(13);
  @include star(14);
  @include star(15);


  input[type="radio"].star-radio {
    display: none;

  }

  $border-color: #8e9f8b;
  $checkbox-size: 15px;

  .custom-controls-input,
  .custom-control-inputs,
  .customs-control-inputs {
    border: 2px solid white;
    box-shadow: 0 0 0 1px $border-color;
    appearance: none;
    border-radius: 50%;
    width: $checkbox-size;
    height: $checkbox-size;
    background-color: #fff;
    vertical-align: middle;
    margin-bottom: 0.5rem;
    margin-left: revert;
    cursor: pointer;

    &:checked {
      background-color: #392;
      box-shadow: 0 0 0 1px #392;
    }

    &~label {
      vertical-align: middle;

    }
  }

  .custom-control-group {
    display: flex;
    flex-wrap: wrap;

    &__item {
      margin-right: 1.5rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;

      &:last-child {
        margin-right: 0;
      }
    }

    &__label {
      margin-left: 0.5rem;
    }
  }

  .custom-controls-input {
    &:checked {
      background-color: #392;
      box-shadow: 0 0 0 1px #392;
    }
  }

  .custom-control-inputs {
    &:checked {
      background-color: red;
      box-shadow: 0 0 0 1px red;
    }
  }

  .customs-control-inputs {
    &:checked {
      background-color: #535557;
      ;
      box-shadow: 0 0 0 1px rgb(72, 68, 68);
    }
  }

}