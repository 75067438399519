.course_learner_view {
    .start_learning_button{
        top: 25%;
        position: absolute;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
    }
    }
.course-curriculum {
    .curriculum_exp_col{
        right: 0px;
        position: absolute;
    }

    .subsection_collapse{
        .panel-body {
            padding: 0px;
            padding-bottom: 15px;
        }
    }

    .panel-title {
        cursor: pointer;
        }
    .panel-title {
        font-size: 14px;
        margin-bottom: 0;
        min-height: 42px;
        padding: 10px;
        cursor: pointer;

    }
    .section-heading {
        font-weight: bold;
        font-size: 0.9rem;
    }

    .sec-heading-edit {
//         top: -15px;
//         left: 15px;
        position: relative;
        font-weight: bold;
        font-size: 0.9rem;
        padding-right: 35px;
        cursor: text;
    }
    .custom-switch-input:checked~.custom-switch-indicator{
            background: #13bfa6 ;
    }

    .sub-section-heading {
        font-size: 0.8rem;
    }

    .section-controls {
        float: right;
//         margin-top: 10px;
//         margin-right: 15px;
    }


    .components-list {
        padding-bottom: 15px;
        border: 1px solid #ebebed;
        .list-group-item {
            padding: 0;
            border: none;

            &.active {
                background: var(--bs-cyan-soft);

                .component-name {
                    font-weight: bold;
                }
            }
        }
        .active-component {
            background: #6c5ffc;
            font-weight: bold;
            color: white;
            .component-icon {
                color: white!important;
            }
        }

        a {
        	display: block;
            padding: 0.75rem 1.25rem;


            .component-icon {
            	vertical-align: middle;
            	font-size: 16px;
            }
        }
    }

    // Learner View
    .progress-icon {
        float: right;
        margin-top: 16px;
        margin-right: 16px;

        i {
            font-family: "Font Awesome 6 Free";
            font-size: 1.25rem;
            color: var(--gray-500);

            &::before {
                content: "\f058";
            }

            &.incomplete {
                font-weight: 400;

            }

            &.completed {
                font-weight: 900;
                color: var(--bs-teal)
            }
        }
    }
}


    .card .card{
        padding : 0px 15px;
    }
    .trn-edit{
        cursor: pointer;
    }
    .view_training_info{
            input:not(.disable_hide_in_view),select:not(.disable_hide_in_view),.card .select2-selection ,.card .select2-selection__rendered,textarea,.form-control:not(.disable_hide_in_view) {
            border: none!important;
            background: transparent!important;
            pointer-events: none;
            color: #4d5875!important;
            cursor: not-allowed;
            user-select: text;

        }
        label{
            font-weight: bolder;
            font-size: 15px;
        }
        .form-select:not(.disable_hide_in_view),.form-control:not(.disable_hide_in_view),.select2-container{
            margin-left: -10px!important;
        }
        .select2-selection__arrow {
            display: none;
        }
        #copy_url{
            display:block !important;
        }
        .hd-views,.btn{
            display:none!important;
        }

        .display_in_view {
            display: block!important;
        }
        .gst_input {
            max-width: 40px;
            padding: 0px;
            text-align: right;
            margin-right: -12px
        }
        .hide_on_edit{
            display: block;
        }
        .hide_on_view{
            display: none;
        }

    }

    .hide_on_edit{
        display: none;
    }
    .sale_order_cost
    {
    th{
        padding: 5px;
        font-size: 14px;
    }
    .gst_input {
        max-width: 70px;
        float: right;
        text-align: right;
    }

    .sale_order_expense th {
        padding: 5px;
        font-size: 14px;
    }
    .sale_order_expense th .hd-small{
        font-size: 12px;
        margin:0px
    }
    }


.product_setting_dr a {
    width: 100%;
    color: #000000bf!important;
}