// Dashboard
.learner-dashboard {
    .course-tile {
        color: #140342;
        
        .tile {
            box-shadow: 1px 2px 10px 3px rgba(0, 0, 0, 0.1);
            min-height: 350px;
            max-height: 350px;
            width: 100%;

            .course-tile-hero {
                background: #736BDD;
                height: 200px;

                .course-logo-wrapper {
                    height: 200px;
                    display: flex;

                    .course-logo {
                        margin: auto;
                        max-width: 100%;
                        max-height: 100%;
                        padding: 10px;
                    }
                }
            }

            .course-tile-info {
                .course-title {
                    font-size: 17px;
                    font-weight: bold;
                }
                .dates {

                }
                .timings {

                }
                .provider {

                }
                .delivery-mode {

                }
            }
        }
    }
}
